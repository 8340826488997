import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initSelectionForm()
    this.element.addEventListener("turbo:frame-load", () => {
      this.initSelectionForm()
    })
  }

  initSelectionForm() {
    const container = this.element // The turbo frame

    // Bind "select all" checkbox within each event zone location group.
    container.querySelectorAll('.allowed-event-zone-ids-for-location-select-all-checkbox').forEach((checkbox) => {
      checkbox.addEventListener('click', function() {
        const parent = this.closest('.allowed-event-zone-ids-for-location')
        if (parent) {
          parent.querySelectorAll("input[type='checkbox']").forEach((input) => {
            input.checked = this.checked
          })
        }
      })
    })

    // Bind "select all" checkbox within each live zone location group.
    container.querySelectorAll('.allowed-live-zone-ids-for-location-select-all-checkbox').forEach((checkbox) => {
      checkbox.addEventListener('click', function() {
        const parent = this.closest('.allowed-live-zone-ids-for-location')
        if (parent) {
          parent.querySelectorAll("input[type='checkbox']").forEach((input) => {
            input.checked = this.checked
          })
        }
      })
    })

    // Bind the "Select All" button for event zones.
    container.querySelectorAll('.select-all-event-zones').forEach((button) => {
      button.addEventListener('click', () => {
        container.querySelectorAll("input[name='user[allowed_event_zone_ids][]']").forEach((input) => {
          input.checked = true
        })
        container.querySelectorAll('.allowed-event-zone-ids-for-location-select-all-checkbox').forEach((chk) => {
          chk.checked = true
        })
      })
    })

    // Bind the "Unselect All" button for event zones.
    container.querySelectorAll('.unselect-all-event-zones').forEach((button) => {
      button.addEventListener('click', () => {
        container.querySelectorAll("input[name='user[allowed_event_zone_ids][]']").forEach((input) => {
          input.checked = false
        })
        container.querySelectorAll('.allowed-event-zone-ids-for-location-select-all-checkbox').forEach((chk) => {
          chk.checked = false
        })
      })
    })

    // Bind the "Select All" button for live zones.
    container.querySelectorAll('.select-all-live-zones').forEach((button) => {
      button.addEventListener('click', () => {
        container.querySelectorAll("input[name='user[allowed_live_zone_ids][]']").forEach((input) => {
          input.checked = true
        })
        container.querySelectorAll('.allowed-live-zone-ids-for-location-select-all-checkbox').forEach((chk) => {
          chk.checked = true
        })
      })
    })

    // Bind the "Unselect All" button for live zones.
    container.querySelectorAll('.unselect-all-live-zones').forEach((button) => {
      button.addEventListener('click', () => {
        container.querySelectorAll("input[name='user[allowed_live_zone_ids][]']").forEach((input) => {
          input.checked = false
        })
        container.querySelectorAll('.allowed-live-zone-ids-for-location-select-all-checkbox').forEach((chk) => {
          chk.checked = false
        })
      })
    })

    // Add listener to individual checkboxes to uncheck the group "select all" if needed.
    container.querySelectorAll('.collection-check-box input[type="checkbox"]').forEach((checkbox) => {
      checkbox.addEventListener('change', function() {
        if (!this.checked) {
          // Find the parent location group and uncheck its "select all" checkbox
          const eventParent = this.closest('.allowed-event-zone-ids-for-location')
          if (eventParent) {
            const selectAll = eventParent.querySelector('.allowed-event-zone-ids-for-location-select-all-checkbox')
            if (selectAll) selectAll.checked = false
          }
          const liveParent = this.closest('.allowed-live-zone-ids-for-location')
          if (liveParent) {
            const selectAll = liveParent.querySelector('.allowed-live-zone-ids-for-location-select-all-checkbox')
            if (selectAll) selectAll.checked = false
          }
        }
      })
    })
  }
}
